import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
//import action
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
// Pages Components
import WelcomeComp from "./WelcomeComp";
import RecentRegisteredUser from "./RecentRegisteredUser";
//i18n
import { withTranslation } from "react-i18next";
//redux
import RecentOrder from "./RecentOrder";
import { getAllserviceCount } from "../../service/dashBoardApi";
const Dashboard = (props) => {
  const [modal, setmodal] = useState(false);
  const [showData, setShowData] = useState([]);

  const reports = [
    {
      title: "ANTI CATFISH CHECK",
      iconClass: "bx-user-circle",
      description: "0",
    },
    {
      title: "CRIMINAL CHECK",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "SOCIAL",
      iconClass: "bx-user-circle",
      description: "0",
    },
    {
      title: "All Checks",
      iconClass: "bx-user-circle",
      description: "0",
    },
  ];

  // const handleApidata = async () => {
  //   let resp = await getAllserviceCount();
  //   if (resp.code == 200) {
  //     let dataresturn = Object.keys(resp.data)?.map((w, i) => {
  //       console.log("dataresturn", resp.data);
  //       if (w.toLowerCase().includes("anti-catfish")) {
  //         return {
  //           title: "anti",
  //           description: resp.data[w],
  //         };
  //       } else if (w.toLowerCase().includes("criminal")) {
  //         return {
  //           title: "criminal",
  //           description: resp.data[w],
  //         };
  //       } else if (w.toLowerCase().includes("All Checks")) {
  //         return {
  //           title: "All Checks",
  //           description: resp.data[w],
  //         };
  //       } else {
  //         return {
  //           title: "social",
  //           description: resp.data[w],
  //         };
  //       }
  //     });
  //     setShowData(dataresturn);
  //   } else {
  //     setShowData(reports);
  //   }
  // };
const handleApidata = async () => {
  let resp = await getAllserviceCount();
  if (resp.code == 200) {
    let dataresturn = Object.keys(resp.data).map((w) => {
      const description = resp.data[w];

      if (w.toLowerCase().includes("anti-catfish")) {
        return {
          title: "ANTI CATFISH CHECK",
          description,
        };
      } else if (w.toLowerCase().includes("criminal")) {
        return {
          title: "CRIMINAL CHECK",
          description,
        };
      } else if (w.toLowerCase().includes("all checks")) {
        return {
          title: "All Checks",
          description,
        };
      } else if (w.toLowerCase().includes("social")) {
        return {
          title: "SOCIAL",
          description,
        };
      }
    });
    setShowData(dataresturn);
  } else {
    setShowData(reports);
  }
};
  useEffect(() => {
    handleApidata();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | BrightCheck</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                {/* <Col md="3" xs="6">
                  <WelcomeComp />
                </Col> */}
                <Col md="3" xs="6">
                  <Card className="mini-stats-wid home-card23">
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-user-circle font-size-24"}></i>
                          </span>
                        </div>
                        <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                          All CHECKS (Gen 2)
                        </p>
                      </div>
                      <div className="flex-grow-1">
                        {showData?.map((w) => {
                          if (w.title == "All Checks") {
                            return <h1 className="mb-0">{w.description}</h1>;
                          }
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3" xs="6">
                  <Card className="mini-stats-wid home-card23">
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-user-circle font-size-24"}></i>
                          </span>
                        </div>
                        <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                          ANTI CATFISH CHECK
                        </p>
                      </div>
                      <div className="flex-grow-1">
                        {showData?.map((w) => {
                          if (w.title == "ANTI CATFISH CHECK") {
                            return <h1 className="mb-0">{w.description}</h1>;
                          }
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3" xs="6">
                  <Card className="mini-stats-wid home-card23">
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-crosshair font-size-24"}></i>
                          </span>
                        </div>
                        <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                          CRIMINAL CHECK
                        </p>
                      </div>
                      <div className="flex-grow-1">
                        {showData?.map((w) => {
                          if (w.title == "CRIMINAL CHECK") {
                            return <h1 className="mb-0">{w.description}</h1>;
                          }
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3" xs="6">
                  <Card className="mini-stats-wid home-card23">
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bxs-user-badge font-size-24"}></i>
                          </span>
                        </div>
                        <p className="text-muted fw-medium mb-0 ms-2  fw-bold">
                          SOCIAL CHECK
                        </p>
                      </div>
                      <div className="flex-grow-1">
                        {showData?.map((w) => {
                          if (w.title == "SOCIAL") {
                            return <h1 className="mb-0">{w.description}</h1>;
                          }
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="12" className="mb-4">
              <RecentRegisteredUser />
            </Col>
            <Col lg="12">
              <RecentOrder />
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
