const typeGroup = [
  { label: "All Types", value: "" },
  { label: "Pre-customer", value: "pre" },
  { label: "Post-customer", value: "post" },
  { label: "Administrator", value: "admin" },
];
const statusGroup = [
  { label: "All Status", value: "" },
  { label: "Active", value: "active" },
  { label: "Suspended", value: "suspended" },
  { label: "Archived", value: "archived" },
  { label: "Marked for deletion", value: "marked_for_deletion" },
];
const statusDelete = [
  { label: "User Deleted", value: 'deleted'  },
  { label: "Active", value: 'active'},
  { label: "Suspended", value: 'suspended' },
  { label: "Archived", value: 'archived' },

];
const roleGroup = [
  { label: "User", value: "user" },
  { label: "Admin", value: "admin" },
];
const orderStatusGroup = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "failed" },
  { label: "Pending", value: "pending" },
  { label: "No match", value: "no match" },
];
const orderServiceGroup = [
  { label: "Anti-Catfish Check", value: "Anti-Catfish" },
  { label: "Criminal Check", value: "Criminal" },
  { label: "Social Check", value: "Social" },
];


const dateFilters = [
  { label: "All", value: "" },
  { label: "Today", value: 1 },
  // { label: "Yesterday", value: "" },
  { label: "Last 7 Days", value: 7 },
  { label: "Last 30 Days", value: 30 },
  { label: "1 Year", value: 365 },
];

export {
  typeGroup,
  roleGroup,
  statusGroup,
  orderStatusGroup,
  orderServiceGroup,
  statusDelete,
  dateFilters
};
