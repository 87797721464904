import CryptoJS from 'crypto-js';
// import ke from '../src/Constant'

  var envkey = process.env.REACT_APP_ID
  var enviv = process.env.REACT_APP_SUB_ID
var key = CryptoJS.enc.Utf8.parse(envkey); //key
var iv = CryptoJS.enc.Utf8.parse(enviv); //optional



// Added By Moinuddin Mansuri
export  const encryptData = (data) => {
    var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encryptedData.toString();
  }



  export  const decryptData = (data) => {

    try {
      var decryptedData = CryptoJS.AES.decrypt(data, key,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });



    return CryptoJS.enc.Utf8.stringify(decryptedData);


    } catch (error) {
      // console.log(error)
    }
   
  }
