import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {  PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, Col, Row, Badge } from "reactstrap";
import { deleteOrder,getOrderAllData,updateOrder } from "service/orderService";
import moment from "moment";
import { showAlert } from "components/Common/AlertMessage";
import OrderModal from "../../components/Order/orderModal";
import DeleteModal from "components/Common/DeleteModal";
import ViewICon from "./../../assets/icons/view-icon.svg";
import { Spinner } from "reactstrap";
import { getPieChartDataForUsers } from "service/dashBoardApi";

const RecentOrder = (props) => {
  const [orderfilter, setOrderfilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const selectRow = { mode: "none" };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [delData, setDelData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: orderData.length||1, // replace later with size(orders),
    custom: true,
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "transaction_id",
      text: "TRANSACTION ID",
    },
    {
      dataField: "_id",
      text: "ORDER ID",
      formatter: (cellContent, row) => (
        <>
          <Link to={`/order/order-details/${row._id}`} className="text-gray">
            {row._id}
          </Link>
        </>
      ),
    },
    {
      dataField: "processer",
      text: "PROCESSOR",
      sort: true,
    },
    {
      dataField: "payment_status",
      text: "PAYMENT STATUS",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.payment_status}
          color={row.badgeClass}
          pill
        >
          {capitalizeFirstLetter(
            row?.payment_status == "success"
              ? "Complete"
              : row.payment_status || ""
          )}
        </Badge>
      ),
    },
    {
      dataField: "first_name",
      text: "USER",
      formatter: (cellContent, row) => (
        <>
          <p>ID {`${row?.user_id} `}</p>
          <p>{row?.email || row?.phone || ""}</p>
        </>
      ),
    },
    {
      dataField: "createdAt",
      text: "DATE AND TIME",
      formatter: (cellContent, row) => (
        <>
          <p>{moment(row?.createdAt).format("MMMM DD,YYYY")}</p>
          <p>{moment(row?.createdAt).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      dataField: "total",
      text: "AMOUNT",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="ps-2">{`$ ${row?.total}`}</p>
        </>
      ),
    },
    {
      dataField: "action",
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div className="text-center">
          <Link
            to={`/order/order-details/${row._id}`}
            type="button"
            color="primary"
            className="btn-sm btn-icon btn-rounded"
          >
            <img title={"View"} src={ViewICon} alt="view icon" />
          </Link>
        </div>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  useEffect(() => {
    let dataBody = {
      "orderId":  "",
      "orderStatus": "",
      "to": "",
      "from": "",
      "flied":  "",
      "transactionId": "",
      "user_status": "",

      "page": 1,
      "limit": 10
    };
    getOrderAll(dataBody)
  },[])

  // useEffect(async () => {
  //   let dataBody = {
  //     "orderId":  "",
  //     "orderStatus": "",
  //     "to": "",
  //     "from": "",
  //     "flied":  "",
  //     "transactionId": "",
  //     "user_status": "",

  //     "page": 1,
  //     "limit": 10
  //   };
  //   getOrderAll(dataBody)
  // }, [orderfilter]);

  const getOrderAll = async (data) => {
    setLoading(true);
    const resp = await getOrderAllData(data);
    if (resp.code == 200) {
      setOrderData(resp.data);
      setLoading(false)
    } else {
      setLoading(false);
    }
  };
 
  //
  const closeModal = () => {
    setEditModal(false);
    setDeleteModal(false);
  };
  const updateHandler = async (data) => {
    setLoading(true);
    const resp = await updateOrder(data._id, data);
    if (resp.code == 200) {
      setEditModal(false);
      showAlert("success", "Order Updated successfully");
      getOrderAll();
    } else {
      setLoading(false);
      showAlert("error", resp.error.massage || "There Is a error try again");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const resp = await deleteOrder(delData.id);
    if (resp.code == 200) {
      setDeleteModal(false);
      setLoading(false)
      showAlert("success", "Order Deleted Successfully");
      getOrderAll();
    } else {
      showAlert("error", "order not deleted yet !");
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <OrderModal
        isOpen={editModal}
        toggle={closeModal}
        editValue={isEdit}
        updateHandler={updateHandler}
      />
      <DeleteModal
        show={deleteModal}
        name={delData?.name}
        onDeleteClick={handleDelete}
        onCloseClick={closeModal}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 text-gray card-title d-flex justify-content-between align-items-center">
            RECENT ORDERS
            <Link to="/orders" className="view-all-link">
              View all »
            </Link>
          </div>
          {!loading ? (
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={EcommerceOrderColumns(toggle)}
            data={orderData || []}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={orderData || []}
                columns={EcommerceOrderColumns(toggle)}
                bootstrap4
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap table-no-check table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

RecentOrder.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(RecentOrder);
