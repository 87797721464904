import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import EditIcon from "./../../assets/icons/edit-icon.svg";
import RemoveICon from "./../../assets/icons/remove-icon.svg";
import { Button, Card, CardBody, Col, Row, Badge } from "reactstrap";
import { getOrders as onGetOrders, typeUser } from "store/actions";
//redux
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import UserModal from "pages/Users/userModal";
import DeleteModal from "components/Common/UserSuspendModal";
import { getUserAllData, deleteUser, getUserAllDataChart } from "../../service/userService";
import { showAlert } from "components/Common/AlertMessage";
import moment from "moment";
import { Bar, Line , Pie, } from "react-chartjs-2";
import Chart from "react-apexcharts";

import { getPieChartDataForUsers } from "service/dashBoardApi";
import { getOrderAllDataChart } from "service/orderService";
const RecentRegisteredUser = (props) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setuserData] = useState([]);
  const [userfilter, setUserfilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const dispatch = useDispatch();
  const [saveDelId, setSaveDelUId] = useState(0);
  const [linechartdata, setlinechartdata] = useState([]);
  const [Piechartdata, setPiechartdata] = useState(null);
  const [timeRange, setTimeRange] = useState("daily"); // Track selected range
    const [timeRangePie, setTimeRangePie] = useState("daily");
  const [currentData, setCurrentData] = useState(null);
  const [timeRangeOrder, setTimeRangeOrder] = useState("daily"); // Track selected range
  const [barchartdata, setbarchartdata] = useState();

  ///old modal
  const [oldModal, setOldModal] = useState(false);
  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: userData?.length || 1, // userData?.length ||
    custom: true,
  };

  const [apexOptions, setApexOptions] = useState({
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `${val.toFixed(2)}%`;
          },
          style: {
            colors: ["#fff"],
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  });

  const [series, setSeries] = useState([]);
  useEffect(() => {
    const capitalizeFirstLetter = (str) =>
      str
        .split(" ")
        .map((word) =>
          word.toLowerCase() === "ios"
            ? "iOS"
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
  
    if (Piechartdata && Piechartdata[timeRangePie]) {
      const { labels, counts, meta } = Piechartdata[timeRangePie];
      const uppercaseLabels = labels.map(capitalizeFirstLetter);
      setApexOptions((prev) => ({
        ...prev,
        labels: uppercaseLabels,
      }));
      setSeries(counts);
      setCurrentData(meta);
    }
  }, [timeRangePie, Piechartdata]);
  

  useEffect(() => {
    dispatch(onGetOrders());
  }, [dispatch]);

  const selectRow = {
    mode: "none",
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const toggleViewModal = (row) => {
    setModal1(!modal1);
    setIsEdit(row);
  };
  const disableDiv = {
    pointerEvents: "none",
    opacity: "0.7",
  };
  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "_id",
      text: "USER ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link
          to={
            row.status != "marked_for_deletion" && row.role == "user"
              ? `/users/user-detail/${row._id}`
              : "#"
          }
          //  to="#"
          className="text-body"
        >
          {row._id}
        </Link>
      ),
    },
    {
      dataField: "email",
      text: "PHONE/EMAIL",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mobile16">{row.mobile_phone || ""}</p>
          <p>{row.email || ""}</p>
        </>
      ),
    },
    {
      dataField: "customerType",
      text: "TYPE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>
            {row.role == "admin" ? (
              "Administrator"
            ) : (
              <>
                {row?.customerType == "pre" ? "Pre-customer" : "Post-customer"}
              </>
            )}
          </p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.status}
          color={row.badgeClass}
          pill
        >
          {capitalizeFirstLetter(row.status || "").replace(/_/g, " ")}
        </Badge>
      ),
    },
    {
      dataField: "createdAt",
      text: "CREATED",
      formatter: (cellContent, row) => (
        <p>{moment(row.createdAt).format("MM/DD/YYYY")}</p>
      ),
    },
    {
      dataField: "action",
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div style={row.status == "marked_for_deletion" ? disableDiv : {}}>
          <Link
            to={row.role == "admin" ? "#" : `/users/user-detail/${row?._id}`}
            onClick={() => {
              dispatch(typeUser("dashboard"));
            }}
            className="text-body fw-bold"
          >
            <img title={"Edit"} src={EditIcon} alt="edit icon" />
          </Link>
          <Button
            type="button"
            className="btn-sm btn-icon btn-rounded"
            onClick={() => {
              onModalOpen(row.first_name);
              setSaveDelUId(row._id);
            }}
          >
            <img title={"Delete"} src={RemoveICon} alt="remove icon" />
          </Button>
        </div>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ];

  useEffect(async () => {
    setLoading(true);
    const resp = await getUserAllData(userfilter);
    if (resp.code == 200) {
      const data = resp.data.users.filter(
        (user) => user.status == "active" || user.status == "suspended"
      );
      setuserData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);
  const onModalOpen = (val) => {
    const name = val ? val + "!" : "this !";
    setDelModal({ show: true, name: name });
  };
  const onClose = () => {
    setDelModal(false);
  };
  const onDelete = () => {
    setDelModal(false);
    handleDelete();
  };
  useEffect(() => {
    getChartData();
  }, [timeRangeOrder]);
  // this function is for line chart data
  const getChartData = async () => {
    setLoading(true);
    const resp = await getUserAllDataChart();
    if (resp.code == 200) {
      setlinechartdata(resp.data);
    } else {
      setLoading(false);
    }
    const resp1 = await getOrderAllDataChart();
    if (resp.code == 200) {
      setbarchartdata(resp1.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  // this is for the pie chart data api
  const getPieChartData = async (data) => {
    setLoading(true);
    const resp = await getPieChartDataForUsers(data);
    if (resp.code == 200) {
      setPiechartdata(resp.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const resp = await deleteUser(saveDelId, "marked_for_deletion");
    if (resp.code == 200) {
      const resp2 = await getUserAllData(userfilter);
      if (resp2.code == 200) {
        let data = resp2.data.users;
        if (Object.keys(userfilter).length == 0) {
          data = data.filter(
            (user) => user.status == "active" || user.status == "suspended"
          );
        }
        setuserData(data);
        showAlert("success", "User Deleted Successfully");
        setLoading(false);
      } else {
        showAlert(
          "error",
          resp2.error.data.message || "there Is A error try again"
        );
        setLoading(false);
      }
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );
      setLoading(false);
    }
  };

  const HandleSetUserData = (data) => {
    setuserData(data);
    setModal1(false);
    setLoading(false);
  };

  // Parth Roka 18/11/2024 For Bar Graph of users (Daily 1-7, Weekly 52 weeks, Monthly 12 months)

  useEffect(() => {
    getChartData();
  }, [timeRange]);

  // Static JSON Data
  // const fullJsonData = {
  //   daily: {
  //     timeRange: "daily",
  //     labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  //     userCounts: [100, 200, 150, 300, 250, 400, 350],
  //     meta: {
  //       unit: "days",
  //       totalUsers: 1750,
  //       averageUsers: 250,
  //     },
  //   },
  //   weekly: {
  //     timeRange: "weekly",
  //     labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  //     userCounts: [1200, 1500, 1300, 1700, 1400],
  //     meta: {
  //       unit: "weeks",
  //       totalUsers: 7100,
  //       averageUsers: 1420,
  //     },
  //   },
  //   monthly: {
  //     timeRange: "monthly",
  //     labels: [
  //       "January",
  //       "February",
  //       "March",
  //       "April",
  //       "May",
  //       "June",
  //       "July",
  //       "August",
  //       "September",
  //       "October",
  //       "November",
  //       "December",
  //     ],
  //     userCounts: [1000, 2000, 1800, 2200, 3000, 2500, 2700, 2800, 3000, 3200, 3500, 4000],
  //     meta: {
  //       unit: "months",
  //       totalUsers: 30700,
  //       averageUsers: 2558.33,
  //     },
  //   },
  // };
  // for getting last 7 days from current
  const getLast7DaysLabelsWithWeekend = () => {
    const labels = [];
    const today = new Date();

    // Start from yesterday
    today.setDate(today.getDate() - 1);

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      const dayOfWeek = date.getDay();
      const formattedDate = date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }); // Format as "MMM DD"

      // if (dayOfWeek === 6) {
      //   labels.push(`Sat ${formattedDate}`);
      // } else if (dayOfWeek === 0) {
      //   labels.push(`Sun ${formattedDate}`);
      // } else {
      labels.push(formattedDate);
      // }
    }

    return labels;
  };



  const fullJsonData = {
    daily: {
      timeRange: "Daily",
      labels: getLast7DaysLabelsWithWeekend(),
      userCounts: linechartdata && linechartdata[0]?.counts,
      meta: {
        unit: "Days",
        totalUsers: linechartdata && linechartdata[0]?.totalUsers,
        averageUsers: linechartdata && linechartdata[0]?.averageUsers,
      },
    },
    weekly: {
      timeRange: "Weekly",
      labels:
        linechartdata &&
        linechartdata[1]?.counts.map((_, index) => `Week ${index + 1}`),
      userCounts: linechartdata && linechartdata[1]?.counts,
      meta: {
        unit: "Weeks",
        totalUsers: linechartdata && linechartdata[1]?.totalUsers,
        averageUsers: linechartdata && linechartdata[1]?.averageUsers,
      },
    },
    monthly: {
      timeRange: "Monthly",
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].slice(0, linechartdata && linechartdata[2]?.counts.length), // Only include labels for available months
      userCounts: linechartdata && linechartdata[2]?.counts,
      meta: {
        unit: "Months",
        totalUsers: linechartdata && linechartdata[2]?.totalUsers,
        averageUsers: linechartdata && linechartdata[2]?.averageUsers,
      },
    },
  };
  // Graph options
  const options = {
    responsive: true,

    legend: {
      display: false,
      position: "top",

      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Users",
        },
      },
    },
  };

  // Prepare data for Chart.js
  const chartData = {
    labels: fullJsonData[timeRange]?.labels,
    datasets: [
      {
        label: `${
          timeRange.charAt(0).toUpperCase() + timeRange.slice(1)
        } Users`,
        data: fullJsonData[timeRange]?.userCounts,
        borderColor: "rgba(75,0,130,1)", // Dark purple for the border
        backgroundColor: "rgba(75,0,130,0.2)", // Lighter purple for the background
      },
    ],
  };

  // Parth Roka 18/11/2024 For Pie chart of user Devices (on basis device like Web, Android, iOS)

 // Track selected range

  useEffect(() => {
    getPieChartData();
  }, [timeRangePie]);
  //
  if (!Piechartdata) return <div>NO data for pichart</div>; // Show loading state

  if (!linechartdata) return <div>No data for graph...</div>; // Show loading state
  
// for dynamic options 
const getDateLabel = (rangeType) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.toLocaleString("default", { month: "short" });
  const currentMonthFull = today.toLocaleString("default", { month: "long" });

  // For daily, show last 7 days excluding today
  if (rangeType === "daily") {
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 1); // Set to yesterday (exclude today)
    const endDate = new Date(today);
    endDate.setDate(today.getDate() - 7); // Show 7 days before yesterday

    return `Daily - ${endDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} - ${startDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }

  // For weekly, Jan 1 to current month (abbreviated month)
  if (rangeType === "weekly") {
    const startOfYear = new Date(currentYear, 0, 1); // Jan 1 of current year
    return `Weekly - ${startOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${currentMonth} ${currentYear}`;
  }

  // For monthly, Jan 1 to current month
  if (rangeType === "monthly") {
    const startOfMonth = new Date(currentYear, today.getMonth(), 1); // 1st day of current month
    return `Monthly - ${startOfMonth.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${today.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }

  // For yearly, Jan 1 to Dec 31 of current year
  if (rangeType === "yearly") {
    const startOfYear = new Date(currentYear, 0, 1); // Jan 1 of current year
    const endOfYear = new Date(currentYear, 11, 31); // Dec 31 of current year
    return `Yearly - ${startOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${endOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }
};
// for bar chart 

  if (!barchartdata) return <div>NO data for pichart</div>; // Show loading state
// const getLast7DaysLabelsWithWeekend = () => {
//   const labels = [];
//   const today = new Date();

//   // Start from yesterday
//   today.setDate(today.getDate() - 1);

//   for (let i = 6; i >= 0; i--) {
//     const date = new Date(today);
//     date.setDate(today.getDate() - i);

//     const dayOfWeek = date.getDay();
//     const formattedDate = date.toLocaleDateString("en-US", {
//       month: "short",
//       day: "numeric",
//     }); // Format as "MMM DD"

//     // if (dayOfWeek === 6) {
//     //   labels.push(`Sat ${formattedDate}`);
//     // } else if (dayOfWeek === 0) {
//     //   labels.push(`Sun ${formattedDate}`);
//     // } else {
//     labels.push(formattedDate);
//     // }
//   }

//   return labels;
// };
  const fullJsonDataOrder1 = {
    daily: {
      timeRange: "Daily",
      labels: getLast7DaysLabelsWithWeekend(), // Dynamic daily labels
      // labels: [
      //   "Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"
      // ], // Set static or dynamically generate labels
      userCounts: {
        freeOrdercounts: barchartdata && barchartdata[0]?.counts?.freeOrdercounts,
        paidOrdercounts: barchartdata && barchartdata[0]?.counts?.paidOrdercounts,
      },
      meta: {
        unit: "Days",
        totalUsers: barchartdata && barchartdata[0]?.totalOrders,
        averageUsers: barchartdata && barchartdata[0]?.averageOrders,
      },
    },
    weekly: {
      timeRange: "Weekly",
      // labels:
      //   barchartdata &&
      //   barchartdata[1]?.counts.map((_, index) => `Week ${index + 1}`),
      labels: barchartdata && Array.isArray(barchartdata[1]?.counts?.freeOrdercounts) 
      ? barchartdata[1]?.counts.freeOrdercounts.map((_, index) => `Week ${index + 1}`)
      : [],// Default to an empty array if counts is not an array
        userCounts: {
          freeOrdercounts: barchartdata && barchartdata[1]?.counts?.freeOrdercounts,
          paidOrdercounts: barchartdata && barchartdata[1]?.counts?.paidOrdercounts,
        },
      meta: {
        unit: "Weeks",
        totalUsers: barchartdata && barchartdata[1]?.totalOrders,
        averageUsers: barchartdata && barchartdata[1]?.averageOrders,
      },
    },
    monthly: {
      timeRange: "Monthly",
      labels: [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
      ].slice(0, barchartdata && barchartdata[2]?.counts.length), // Ensure to slice based on the available months
      //   "January",
      //   "February",
      //   "March",
      //   "April",
      //   "May",
      //   "June",
      //   "July",
      //   "August",
      //   "September",
      //   "October",
      //   "November",
      //   "December",
      // ].slice(0, barchartdata && barchartdata[2]?.counts.length), // Only include labels for available months
     userCounts: {
      freeOrdercounts: barchartdata && barchartdata[2]?.counts?.freeOrdercounts,
      paidOrdercounts: barchartdata && barchartdata[2]?.counts?.paidOrdercounts,
    },
      meta: {
        unit: "Months",
        totalUsers: barchartdata && barchartdata[2]?.totalOrders,
        averageUsers: barchartdata && barchartdata[2]?.averageOrders,
      },
    },
  };
 
  // Graph options
  // const optionsOrder1 = {
  //   responsive: true,
  
  //     legend: {
  //       display: false,
  //       position: "top",
  //     },
  //     tooltip: {
  //       enabled: true,
  //     },

  //   scales: {
  //     x: {
  //       title: {
  //         display: true,
  //         text: "Time",
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: "Orders",
  //       },
  //     },
  //   },
  // };
  const optionsOrder1 = {
    responsive: true,

      legend: {
        display: false, // Show the legend for Free and Paid
        position: "top",
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },

    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Orders",
        },
        min: 0,  // Make sure the Y-axis starts from 0
        beginAtZero: true, // Ensure it starts from 0
      },
    },
  };
  
  
  // Prepare data for Chart.js
// const chartDataOrder1 = {
//   labels: fullJsonDataOrder1[timeRangeOrder]?.labels,
//   datasets: [
//     {
//       label: `${
//         timeRangeOrder.charAt(0).toUpperCase() + timeRangeOrder.slice(1)
//       } Orders`,
//       data: fullJsonDataOrder1[timeRangeOrder]?.userCounts,
//       borderColor: "rgba(75,192,192,1)",
//       backgroundColor: new Array(
//         fullJsonDataOrder1[timeRangeOrder]?.userCounts?.length
//       ).fill("#9966FF"), // Purple color for all bars
//     },
//   ],
// };
const chartDataOrder1 = {
  labels: fullJsonDataOrder1[timeRangeOrder]?.labels || [], // Check that labels are not undefined
  datasets: [
    {
      label: "Free Orders",
      data: fullJsonDataOrder1[timeRangeOrder]?.userCounts?.freeOrdercounts || [],
      borderColor: "rgba(75,192,192,1)",
      backgroundColor: "#4BC0C0",
    },
    {
      label: "Paid Orders",
      data: fullJsonDataOrder1[timeRangeOrder]?.userCounts?.paidOrdercounts || [],
      borderColor: "rgba(153,102,255,1)",
      backgroundColor: "#9966FF",
    },
  ],
};



// for dynamic select 
const getDateLabel1 = (rangeType) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.toLocaleString("default", { month: "short" });
  const currentMonthFull = today.toLocaleString("default", { month: "long" });

  // For daily, show last 7 days excluding today
  if (rangeType === "daily") {
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 1); // Set to yesterday (exclude today)
    const endDate = new Date(today);
    endDate.setDate(today.getDate() - 7); // Show 7 days before yesterday

    return `Daily - ${endDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} - ${startDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }

  // For weekly, Jan 1 to current month (abbreviated month)
  if (rangeType === "weekly") {
    const startOfYear = new Date(currentYear, 0, 1); // Jan 1 of current year
    return `Weekly - ${startOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${currentMonth} ${currentYear}`;
  }

  // For monthly, Jan 1 to current month
  if (rangeType === "monthly") {
    const startOfMonth = new Date(currentYear, today.getMonth(), 1); // 1st day of current month
    return `Monthly - ${startOfMonth.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${today.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }

  // For yearly, Jan 1 to Dec 31 of current year
  if (rangeType === "yearly") {
    const startOfYear = new Date(currentYear, 0, 1); // Jan 1 of current year
    const endOfYear = new Date(currentYear, 11, 31); // Dec 31 of current year
    return `Yearly - ${startOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} to ${endOfYear.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })} ${currentYear}`;
  }
};
  return (
    <React.Fragment>
      <UserModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editValue={isEdit}
        userfilter={userfilter}
        HandleSetUserData={HandleSetUserData}
        setLoading={setLoading}
      />
      <DeleteModal
        show={delmodal}
        onDeleteClick={onDelete}
        onCloseClick={onClose}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 text-gray card-title d-flex justify-content-between align-items-center">
            User 
          </div>
<div className="d-flex justify-content-between align-items-center">
          {/* Parth Roka 18/11/2024 Graph of users (Daily 1-7, Weekly 52 weeks, Monthly 12 months) */}
          <div style={{ width: "50%", margin: "auto", marginRight: '15px' }}>  
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "20px" }}
            >
              <h4>Registered Users</h4>
              <select
                className="chart-seleact-new"
                style={{ width: "300px", padding: "10px" }}
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
              >
                <option value="daily">{getDateLabel("daily")} </option>
                <option value="weekly">{getDateLabel("weekly")} </option>
                <option value="monthly">{getDateLabel("yearly")} </option>
                {/* <option value="yearly">{getDateLabel("yearly")}</option> */}
              </select>
            </div>
            <Line data={chartData} options={options} />
            <div style={{ marginTop: "20px", textAlign: "left" }}>
              <h4>Meta Data</h4>
              <p>
                <strong>Unit:</strong> {fullJsonData[timeRange]?.meta?.unit}
              </p>
              <p>
                <strong>Total Users:</strong>{" "}
                {fullJsonData[timeRange]?.meta?.totalUsers}
              </p>
              <p>
                <strong>Average Users:</strong>{" "}
                {fullJsonData[timeRange]?.meta?.averageUsers?.toFixed(2)}
              </p>
            </div>
          </div>

          {/* Parth Roka 18/11/2024 For Pie chart of user Devices (on basis device like Web, Android, iOS) */}

          <div  style={{ width: "50%", margin: "auto", }}>
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "20px" }}
            >
              <h4>Device Type</h4>
              <select
                style={{ width: "300px", padding: "10px" }}
                value={timeRangePie}
                onChange={(e) => setTimeRangePie(e.target.value)}
              >
                <option value="daily">{getDateLabel("daily")} </option>
                <option value="weekly">{getDateLabel("weekly")} </option>
                <option value="monthly">{getDateLabel("yearly")} </option>
              </select>
            </div>
            <Chart
              options={apexOptions}
              series={series}
              type="pie"
              width="380"
              style={{justifyItems: "center"}}
            />
            {/* <Pie data={chartDataPie} options={optionsPie} /> */}
            {currentData && (
              <div style={{ marginTop: "20px", textAlign: "left" }}>
                <h4>Meta Data</h4>
                <p>
                  <strong>Total Users:</strong>{" "}
                  {currentData.totalUserCount || 0}
                </p>
                <p>
                  <strong>Web:</strong> {currentData.webPercentage || 0}%
                </p>
                <p>
                  <strong>Android:</strong> {currentData.androidPercentage || 0}
                  %
                </p>
                <p>
                  <strong>iOS:</strong> {currentData.iosPercentage || 0}%
                </p>
                <p>
                  <strong>Unknown Percentage:</strong>{" "}
                  {currentData.unknownPercentage || 0}%
                </p>
              </div>
            )}
          </div>
          </div>
        </CardBody>
      </Card>
      <Card>
                    <CardBody>
                    <div className="mb-4 h4 text-gray card-title d-flex justify-content-between align-items-center">
            Order
          </div>
                      <div style={{ width: "80%", margin: "auto" }}>
                        <div
                          style={{ marginBottom: "20px" }}
                          className="d-flex justify-content-between"
                        >
                          <h2>Orders</h2>
                          <select
                            style={{ width: "300px", padding: "10px" }}
                            value={timeRangeOrder}
                            onChange={(e) => setTimeRangeOrder(e.target.value)}
                          >
                            <option value="daily">
                              {getDateLabel1("daily")} 
                            </option>
                            <option value="weekly">
                              {getDateLabel1("weekly")} 
                            </option>
                            <option value="monthly">
                              {getDateLabel1("yearly")} 
                            </option>
                          </select>
                        </div>
                        {/* Replace Line with Bar */}
                        <Bar data={chartDataOrder1} options={optionsOrder1} />
                        <div style={{ marginTop: "20px", textAlign: "left" }}>
                          <h4>Meta Data</h4>
                          <p>
                            <strong>Unit:</strong>{" "}
                            {fullJsonDataOrder1[timeRangeOrder].meta.unit}
                          </p>
                          <p>
                            <strong>Total Orders:</strong>{" "}
                            {fullJsonDataOrder1[timeRangeOrder].meta.totalUsers}
                          </p>
                          <p>
                            <strong>Average Orders:</strong>{" "}
                            {fullJsonDataOrder1[
                              timeRangeOrder
                            ].meta.averageUsers.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
      <Card>
        <CardBody>
          <div className="mb-4 h4 text-gray card-title d-flex justify-content-between align-items-center">
            RECENTLY REGISTERED USERS
            <Link to="/users" className="view-all-link">
              View all »
            </Link>
          </div>

          {!loading ? (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={EcommerceOrderColumns(toggle)}
              data={userData || []}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={userData || []}
                  columns={EcommerceOrderColumns(toggle)}
                  bootstrap4
                  search
                >
                  {(toolkitProps) => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="id"
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap table-no-check table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Spinner
                style={{ width: "2rem", height: "2rem" }}
                children={false}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

RecentRegisteredUser.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(RecentRegisteredUser);
