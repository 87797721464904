import axios from "axios";
import { showAlert } from "components/Common/AlertMessage";


export const getUserAllData = async (data) => {
  let token = localStorage.getItem("token");
  try {
    // changed by kunal 22-11-24 for fiters 
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v2/user/get-all-users`,
      // `${process.env.REACT_APP_API_URL}/admin/user/get-all`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};

export const getUserAllDataChart = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v2/user/stats`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};


export const deleteUser = async (id, type) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/user/delete-user/${id}?type=${type}`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};

export const updateUser = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/user/update-user/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};

export const resetPassword = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.put(
      `${process.env.REACT_APP_API_URL}/admin/user/resetpassword/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};

export const addUser = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user/create`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};

export const getUserById = async (id) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/user/get/${id}`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};

export const getUserProfile = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/fetch/profile`,
      {
        headers: { Authorization: token },
      }
    );
   
    if (resp.data.code == 200) {
          return resp.data;
    } 
    else {
      throw new Error(resp);
    }
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.setItem("status" , err.response.status );
      localStorage.removeItem("authUser");
      localStorage.removeItem("token");

    }
    return {
      data: "",
      error: err.response,
      status: 400,
    };
  }
};
