import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Select from "react-select";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Form,
  Label,
  Input,
} from "reactstrap";
import EditIcon from "./../../assets/icons/edit-icon.svg";
import RemoveICon from "./../../assets/icons/remove-icon.svg";
import { getUserAllData, deleteUser } from "../../service/userService";
import { Spinner } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import UserModal from "pages/Users/userModal";
import DeleteModal from "components/Common/UserSuspendModal";
import { typeGroup, statusGroup, dateFilters } from "../Common/DropdownGroup";
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import Swal from "sweetalert2";
import moment from "moment";
import { showAlert } from "components/Common/AlertMessage";
import { DebounceInput } from "react-debounce-input";
import { typeUser, userRowData } from "store/actions";

const UserTable = (props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const [sValue, setSValue] = useState("")
  const [type, setType] = useState(null);
  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState({});
  const [userData, setUserData] = useState([]);
  const [backUpdata, setBackUpdata] = useState([]);
  const [userfilter, setUserfilter] = useState({});
  const [defaultUserfilter, setdefaultUserfilter] = useState({
    status: ["active", "Suspended"],
  });
  const [saveDelId, setSaveDelUId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fliedSearcg, setFliedSearch] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [TfilterDate, setTfilterDate] = useState("");
  const [device_type, setdevice_type] = useState("");
  ///old modal
  const [oldModal, setOldModal] = useState(false);



  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);

  const [countShow, setCountShow] = useState(0);
const [limit, setlimit] = useState(10);
const [pageSize, setPageSize] = useState(10);





  const selectRow = {
    mode: "none",
  };
  const disableDiv = {
    pointerEvents: "none",
    opacity: "0.7",
  };

  function capitalizeFirstLetter(string) {
    const str = string.charAt(0).toUpperCase() + string.slice(1);
    return str.replace(/_/g, " ");
  }
  // useEffect(async () => {
  //   setLoading(true);
  //   const resp = await getUserAllData(userfilter);
  //   if (resp.code == 200) {
  //     setBackUpdata(resp.data.users);
  //     handleSearchFiledApi(fliedSearcg, resp.data.users);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [userfilter, fliedSearcg, typeValue]);



  useEffect(() => {
    if (
      sValue ||
      typeValue ||
      fliedSearcg ||
      currentPage ||
      TfilterDate ||
      device_type
    ) {
      getOrderAll();
    } else {
      getOrderAll();
    }
  }, [
    typeValue,
    sValue,
    fliedSearcg,
    currentPage,
    limit,
    TfilterDate,
    device_type,
  ]);




  const getOrderAll = async (pageNo) => {
    setLoading(true);

    let dataBody = {
      flied: fliedSearcg ? fliedSearcg : "",
      type: typeValue ? typeValue : "",
      status: sValue ? sValue : "",
      page: currentPage,
      limit: limit,
      days: TfilterDate.value ? TfilterDate.value : "",
      device_type: device_type?device_type.value:"",
      //write no of days
    };
    const resp = await getUserAllData(dataBody);
    if (resp.code == 200) {
      setLoading(false)
      setUserData(resp.data.users);
      // handleApiFetchdata(resp.data.users);
      setCountShow(resp.count)
      setData(resp.data.users);
      setTotalPages(resp.totalPages);

    } else {
      setLoading(false);
    }
  };

const handlePageSizeChange = (selectedOption) => {
  setPageSize(selectedOption.value);
  setlimit(selectedOption.value);
  setCurrentPage(1); // Reset to the first page
  setStartPage(1); // Reset startPage for navigation
};
  //pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: userData?.length || 0, // userData?.length ||
    custom: true,
  };
  // const { SearchBar } = Search;

  function handleStatusGroup(e) {
    setStatus(e);
    setUserfilter({
      ...userfilter,
      status: e.value,
    });
  }

  function handleTypeGroup(e) {
    setType(e);
    setUserfilter({
      ...userfilter,
      type: e.value,
    });
  }

  function handleSearch(e) {
    setUserfilter({
      ...userfilter,
      field: e.target.value,
    });
    // setSearchValue(e.target.value);
  }

  const toggleViewModal = (row) => {
    setModal1(!modal1);
    setIsEdit(row);
  };

  const toggleViewModalold = () => setOldModal(!oldModal);

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "_id",
      text: "USER ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <Link
            to={
              row.status != "marked_for_deletion" && row.role == "user"
                ? `/users/user-detail/${row._id}`
                : "#"
            }
            //  to='#'
            className="text-body"
          >
            {row?._id}
          </Link>
        </>
      ),
    },
    {
      dataField: "email",
      text: "PHONE/EMAIL",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {/* <p className="mobile16">{row?.mobile_phone || ""}</p> */}
          <p className="mobile16">
            {row?.mobile_phone ? "+" + row?.mobile_phone : "" || ""}
          </p>
          <p>{row?.email || ""}</p>
        </>
      ),
    },
    {
      dataField: "customerType",
      text: "TYPE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>
            {row.role == "admin" ? (
              "Administrator"
            ) : (
              <>
                {row?.customerType == "pre" ? "Pre-customer" : "Post-customer"}
              </>
            )}
          </p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row?.status}
          color={row?.badgeClass}
          pill
        >
          {capitalizeFirstLetter(row?.status || "")}
        </Badge>
      ),
    },
    {
      dataField: "device_type",
      text: "Device",
      formatter: (cellContent, row) => <p>{row?.deviceType}</p>,
    },
    {
      dataField: "createdAt",
      text: "CREATED",
      formatter: (cellContent, row) => (
        <p>{moment(new Date(row?.createdAt)).format("MM/DD/YYYY")}</p>
      ),
    },
    {
      dataField: "action",
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div style={row.status == "marked_for_deletion" ? disableDiv : {}}>
          <Link
            to={row.role == "admin" ? "#" : `/users/user-detail/${row?._id}`}
            onClick={() => {
              dispatch(typeUser("users"));
            }}
            className="text-body fw-bold"
          >
            <img title={"Edit"} src={EditIcon} alt="edit icon" />
          </Link>

          <Button
            type="button"
            className="btn-sm btn-icon btn-rounded"
            onClick={() => {
              onModalOpen(row?.first_name);
              setSaveDelUId(row?._id);
            }}
            // onClick={toggleViewModalold}
          >
            <img title={"Delete"} src={RemoveICon} alt="remove icon" />
          </Button>
        </div>
      ),
    },
  ];
  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ];

  const onModalOpen = (val) => {
    // const name = val ? val + "!" : "this !";
    setDelModal(true);
  };

  const onClose = () => {
    setDelModal(false);
  };
  const onDelete = () => {
    setDelModal(false);
    handleDelete();
  };

  const handleDelete = async () => {
    setLoading(true);
    const resp = await deleteUser(saveDelId, "marked_for_deletion");
    if (resp.code == 200) {
      const resp2 = await getUserAllData(userfilter);
      if (resp2.code == 200) {
        showAlert("success", "User Deleted Successfully");
        let data = resp2.data.users;
        if (Object.keys(userfilter).length === 0) {
          data = data.filter(
            (user) => user.status == "active" || user.status == "suspended"
          );
        }
        // setType(null);
        // setStatus(null);

        setBackUpdata(data);
        handleSearchFiledApi(fliedSearcg, resp2.data.users);
        setLoading(false);
      } else {
        showAlert(
          "error",
          resp.error.data.message || "there Is A error try again"
        );
        setLoading(false);
      }
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );

      setLoading(false);
    }
  };

  const HandleSetUserData = (data) => {
    setUserData(data);
    setModal1(false);
    setLoading(false);
  };

  const handleSearchFiledApi = (value, apiData) => {
    let data = apiData;
    if (Object.keys(userfilter).length === 0) {
      data = data.filter(
        (user) => user.status == "active" || user.status == "suspended"
      );
    }
    if (fliedSearcg) {
      data = apiData;
      let dataone = data?.filter((w) => {
        return (
          w?.email?.toLowerCase()?.includes(fliedSearcg?.toLowerCase()) ||
          w?.mobile_phone
            ?.toLowerCase()
            ?.includes(fliedSearcg?.toLowerCase()) ||
          w?.first_name?.toLowerCase()?.includes(fliedSearcg?.toLowerCase()) ||
          w?.last_name?.toLowerCase()?.includes(fliedSearcg?.toLowerCase()) ||
          w?._id?.toLowerCase()?.includes(fliedSearcg?.toLowerCase())
        );
      });
      data = dataone;
    }
    if (typeValue) {
      let dataotwo = data?.filter((w) => {
        return (
          w?.role == typeValue ||
          (w?.customerType == typeValue && w?.role == "user")
        );
      });
      data = dataotwo;
    }
    setUserData(data);
    // setFliedSearch(value);
    setLoading(false);
  };

  const handleSearchFiled = (value) => {
    // let data = backUpdata?.filter((w) => {
    //   return (
    //     w?.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
    //     w?.mobile_phone?.toLowerCase()?.includes(value?.toLowerCase())
    //   );
    // });
    // setUserData(data);
    setFliedSearch(value);
  };



  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const endPage = Math.min(startPage + 4, totalPages);


  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={oldModal} toggle={toggleViewModalold} /> */}

      <UserModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editValue={isEdit}
        userfilter={userfilter}
        HandleSetUserData={HandleSetUserData}
        setLoading={setLoading}
      />
      <DeleteModal
        show={delmodal}
        onDeleteClick={onDelete}
        onCloseClick={onClose}
        status={"Delete"}
      />
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <div className="row">
            <div className="col-3 mb-3">
              <Label className="form-label">Search Users Id</Label>
              <DebounceInput
                maxLength={40}
                minLength={2}
                name="userEmail"
                className="form-control field-search"
                placeholder="By email address or phone"
                debounceTimeout={300}
                onChange={(e) => {
                  setFliedSearch(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-3 mb-3">
              <Label className="form-label">Filter by Type</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Types
                  </span>
                }
                value={type}
                onChange={(e) => {
                  setTypeValue(e.value);
                  setType(e);
                  setCurrentPage(1);
                }}
                options={typeGroup}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-3 mb-3">
              <Label className="form-label">Filter by Status</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Statuses
                  </span>
                }
                value={status || null}
                onChange={(e) => {
                  setStatus(e);
                  setSValue(e.value);
                  setCurrentPage(1);
                }}
                options={statusGroup}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-3 mb-3">
              <Label className="form-label">Filter by Days</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Days
                  </span>
                }
                value={TfilterDate}
                onChange={(e) => {
                  setTfilterDate(e);
                  setCurrentPage(1);
                }}
                options={dateFilters}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-3 mb-3">
              <Label className="form-label"> Device type Filte</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Device type
                  </span>
                }
                value={device_type}
                onChange={(e) => {
                  setdevice_type(e);
                  setCurrentPage(1);
                }}
                options={[
                  { label: " All Device type", value: "" },
                  { label: "Android", value: "Android" },
                  // { label: "Yesterday", value: "" },
                  { label: "Ios", value: "ios" },
                  { label: "Web", value: "web" },
                ]}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-4 mb3">
            <Label className="form-label">&nbsp;</Label>
            <button
              className=" btn-primary d-block"
              style={{ padding: "13px" }}
              onClick={() => {
                setdevice_type("")
                setStatus("")
                setSValue("")
                setType("")
                setFliedSearch("")
                setTfilterDate("")
                setTypeValue("")
              }}
            >
              Clear
            </button>
            </div>
          </div>
        </Form>
      </div>
      <Card>
        <CardBody>
          {userData?.length == 0 && !loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <h2>No Data Found</h2>
            </div>
          ) : (
            <>
              <div className="col-12 col-md-6 ">
                <div className="mb-3">
                  <Label className=" ">Records per page</Label>
                  <Select
                    value={{
                      label: `${pageSize} records`,
                      value: pageSize,
                    }}
                    onChange={handlePageSizeChange}
                    className="w-50"
                    options={[
                      { label: "5 records", value: 5 },
                      { label: "10 records", value: 10 },
                      { label: "20 records", value: 20 },
                      { label: "50 records", value: 50 },
                      { label: "100 records", value: 100 },
                    ]}
                  />
                </div>
              </div>
              {!loading ? (
                <>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive orders-table-main-div"
                          id="harshu"
                        >
                          <BootstrapTable
                            keyField="id"
                            data={data}
                            columns={EcommerceOrderColumns(toggle)}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap table-no-check table-check"
                            }
                            headerWrapperClasses={"table-light"}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* {data?.length + "/" + countShow} */}
                    {/* Showing {currentPage <= 1 ? 1 : (currentPage - 1) * 10 + 1} to {currentPage * 10 <= countShow ? currentPage * 10 : countShow}  of {countShow} entries */}
                    <div className="row align-items-center p-3">
                      <div className="col-12 col-md-6 text-start mb-2 mb-md-0">
                        Showing{" "}
                        {currentPage <= 1
                          ? 1
                          : (currentPage - 1) * pageSize + 1}{" "}
                        to {Math.min(currentPage * pageSize, countShow)} of{" "}
                        {countShow} entries
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-md-end">
                        
                      </div>
                    </div>
                  </React.Fragment>
                  <div className="pagination pagination-rounded justify-content-center mt-4">
                    <button
                      onClick={handlePreviousLastPage}
                      disabled={currentPage === 1}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {"<"}
                    </button>
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, index) => startPage + index
                    ).map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        disabled={currentPage === page}
                        className={
                          currentPage === page
                            ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                            : "test btn btn-light  btn-xl rounded-circle m-2"
                        }
                      >
                        {page}
                      </button>
                    ))}
                    {totalPages > startPage + 4}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {">"}
                    </button>
                    <button
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {">>"}
                    </button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: checkmargin,
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              )}

              {/* {!loading ? (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="_id"
                  columns={EcommerceOrderColumns(toggle)}
                  data={userData || []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="_id"
                      data={data || []}
                      columns={EcommerceOrderColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="_id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-no-check table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-center">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              )} */}
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

UserTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(UserTable);
